import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ComponentFactoryResolver, HostListener, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProfileComponent } from 'src/app/account/profile/profile.component';
import { NotificationsComponent } from 'src/app/shared/notifications/notifications.component';
import { AuthenticationService } from 'src/app/_core/services/authentication.service';
import { CommonService } from 'src/app/_core/services/common.service';
import { DataService } from 'src/app/_core/services/data.service';
import { DeviceInfoService } from 'src/app/_core/services/device-info.service';
import { FirestoreService } from 'src/app/_core/services/firestore.service';
import { HelperService } from 'src/app/_core/services/helper.service';
import {environment} from '../../../environments/environment';
import {CookieService} from '../../_core/services/cookie.service';

@Component({
  selector: 'app-header',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0%)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0%)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ],
    )
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  navigationItems: any[] = [];
  isShowSearchBar: boolean = false;
  isLoginComponentOpen: boolean = false;
  isDialogOpen: boolean = false;
  searchKey: string = "";
  returnUrl: any;
  isLoginDialogOpen: boolean = false;
  profiles: any[] = [];
  isShowWatchList: boolean = false;
  isShowPaymentWarning: boolean = false;
  selectedProfileIndex: number;
  arrowClicked: boolean = false;
  selectedUser: any;
  isMobileView:boolean= false;

  notifyClicked: boolean = false;

  constructor(
    private commonService: CommonService,
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private matDialog: MatDialog,
    private helperService: HelperService,
    private firestoreService: FirestoreService,
    private dataService: DataService,
    private deviceInfoService: DeviceInfoService,
    private vf: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private ngZone: NgZone
  ) {
    // this.returnUrl = router.url;
  }

  ngOnInit() {
    console.log(this.router.url);
    let urlString = this.router.url.slice(0, 8);
    console.log(urlString);
    if (urlString === '/?fbclid') {
      window.location.href = 'https://dflix.com/home';
    }
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontKeyPairId, 'KZNF8JQLDMUS3', null);
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontPolicy, 'eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly8qLmRmbGl4LmNvbS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzE5NTk3Njg4MDM3fX19XX0_', null);
    this.cookieService.setCookieForSign(environment.cookies.cloudFrontSignature, 'tXZwnTZLE72ZDvobVw8M8IM6JHA~4fi3jd-POxMmhMrs0lYfVRrR3p-qylbOolAaPLFUqJnjq7ypXm9cI9v8Kw0acNXw~hUZMgKJN9NHLP3ODjzEnn8thwmHVQv2QYegb~slV2lWEMql7fAoik~7s9P4ZGMx6bBUy7A2Qj4YrURsXu9S1E4rWSz9KsMmbXkgRKE2DcoCZhNO1qmUH41OnxguZAd-T1FnqV5f1dl~DpWElr6Zi2zlagpf2FO4pu80Qzp2SXdvCeUa4wAkLVGsVwop5SCDvCneLzuJoxAerqkaAF~DNZ6Rei5nJX98Sn4QkEiKlP~aQoXvOlbiv0CbnA__', null);
    this.checkDeviceInfo();
    setTimeout(() => {
      this.loadNavigations();
      this.onScollEvent();
      this.getProfiles();
    }, 0);
    this.checkPaymentStatus();
    this.refreshLoginCredential();
    // this.subscribeToWatchList();
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (val.url == '/other/search/view') {
          this.addScollHeaderColor();
        }
      }
    });
  }

  checkDeviceInfo() {
    setTimeout(() => {
      if (this.deviceInfoService.getDeviceType() == 'MOBILE' || this.deviceInfoService.getDeviceType() == 'TABLET') {
        this.isMobileView = true;
      }
    }, 0);
  }

  checkPaymentStatus() {
    let currentUser = this.authenticationService.currentUser();
    let navBarElement = document.getElementById('nav-bar');

    if (!!currentUser && currentUser.grace_period_on) {
      this.isShowPaymentWarning = currentUser.grace_period_on;
      if (navBarElement != null) {
        navBarElement.style.top = '125px';
      }
    } else {
      this.isShowPaymentWarning = false;
      if (navBarElement != null) {
        navBarElement.style.top = '0px';
      }
    }
  }

  hidePaymentWarning() {
    this.isShowPaymentWarning = false;
    let navBarElement = document.getElementById('nav-bar');
    navBarElement.style.top = '0px';
  }

  subscribeToWatchList() {
    this.dataService.initialAddWatchListSource.subscribe(
      (res) => {
        this.isShowWatchList = (!!res && res.length > 0) ? true : false;
        let currentIndex = this.navigationItems.findIndex(ele => ele.screenId == -1);
        if (this.authenticationService.currentUser() && this.isShowWatchList && currentIndex == -1 && !this.isMobileView) {
          this.navigationItems.push({
            name: 'My List',
            screenId: -1
          });
        }
        if (currentIndex != -1 && !this.isShowWatchList) {
          this.navigationItems.splice(currentIndex, 1);
          this.loadNavigations();
        }
      }
    );
  }

  refreshLoginCredential() {
    this.dataService.initialLoginCredentail.subscribe(
      (res) => {
        if (res) {
          this.loadNavigations();
          this.getProfiles();
          this.checkPaymentStatus();
        }
      }
    );
  }

  getProfiles() {
    let user = this.authenticationService.currentUser();
    if (user) {
      this.authenticationService.getAllProfileByUserId(user.user_id)
        .subscribe(
          (res) => {
            this.profiles = res.map((profile, index) => {
              profile['isSelected'] = (this.authenticationService.selectedProfile().id == profile.id) ? true : false;
              if (profile.isSelected) {
                this.selectedProfileIndex = index;
              }
              return profile;
            });

          });
    }
    this.selectedUser = this.authenticationService.selectedProfile();
  }
  switchProfile(profile, index) {
    this.authenticationService.setProfileCookie(profile);
    this.dataService.refreshLoginCredential(true);

    for (let i = 0; i < this.profiles.length; i++) {
      this.profiles[i].isSelected = false;
    }
    this.profiles[index].isSelected = true;
    this.selectedProfileIndex = index;
  }

  @HostListener('window:scroll', []) // for window scroll events
  onScollEvent() {
    if (this.router.url.split('/')[1] == 'other') {
      this.addScollHeaderColor();
    } else {
      let paymentHoldWarningElement = document.getElementById('paymentHoldContainer');
      if (paymentHoldWarningElement) {
        let navBarElement = document.getElementById('nav-bar');
        let position = 125 - document.documentElement.scrollTop;

        position = (position < 0) ? 0 : position;

        navBarElement.style.top = `${position}px`;
      }
      if (document.documentElement.scrollTop > 5) {
        this.addScollHeaderColor();
      } else {
        this.addNormalHeaderColor();
      }
    }
  }

  addScollHeaderColor() {
    var element: any = document.getElementById('nav-bar');
    var element1: any = document.getElementById('badge');

    if (element != null) {
      element.classList.remove('nav-bar-color');
      element.classList.add('nav-bar-color-scroll');
    }
    if (element1 != null) {
      element1.classList.add('scroll-down-badge');
    }
  }

  addNormalHeaderColor() {
    var element: any = document.getElementById('nav-bar');
    var element1: any = document.getElementById('badge');

    if (element != null) {
      element.classList.remove('nav-bar-color-scroll');
      element.classList.add('nav-bar-color');
    }
    if (element1 != null) {
      element1.classList.remove('scroll-down-badge');
    }

  }

  /**
   * Getting the navigation bar
   */
  loadNavigations() {
    this.commonService.getMenu()
      .subscribe((screens: any) => {
        this.navigationItems = screens.map((screen: any) => {
          return {
            name: screen.name,
            screenId: screen.id
          };
        });
        this.navigationItems.push({
          name: 'My List',
          screenId: -1
        });

        if (this.router.url == '/') {
          this.router.navigate([this.helperService.changeRouterLink(this.navigationItems[0].name)]);
          this.helperService.updateMainComponent(this.navigationItems[0].screenId);
        } else {
          //This part is here to handle direct route calls except from internal routing
          var currentRoute = this.router.url.split('/')[1];
          var foundNav = this.navigationItems.find((nav) => {
            if (this.helperService.changeRouterLink(nav.name) == currentRoute) {
              return nav;
            }
          });
          if (!!foundNav) {
            this.router.navigate([this.helperService.changeRouterLink(foundNav.name)]);
            this.helperService.updateMainComponent(foundNav.screenId);
          } else if (this.router.url.split('/').length != 4) {//handle the route for detail view
            this.router.navigate(['other', 'page-not-found']);
          }
        }
        this.showBarInitially();
      });
  }

  showBarInitially() {
    var out = true;
    this.navigationItems.forEach((element: any, index: any) => {
      if (out && (this.router.url == '/' || this.router.url == `/${this.helperService.changeRouterLink(element.name)}`)) {
        out = false;
        setTimeout(() => {
          var ele = document.getElementById(`${element.name}${index}`);
          if (ele != null) {
            ele.style.borderColor = '#0087A5';
          }
        }, 0);
      }
    });
  }

  /**
   * Convert screen name to router name
   * @param screenName screenName
   * @param index index
   */
  // getRouterLink(name: any) {
  //   // if (index === 0) {
  //   //   return '/';
  //   // }
  //   const camelCase = name.replace(/\s(.)/g, ($1: any) => $1.toUpperCase())
  //     .replace(/\s/g, '')
  //     .replace(/^(.)/, ($1: any) => $1.toLowerCase());

  //   return camelCase.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
  //     .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
  //     .toLowerCase()
  // }

  /**
   * Navigate to the route
   * @param page Clicked page
   * @param index index
   */
  navigate(page: any, index: number) {
    // element.classList.add("nav-bar-color");
    this.ngZone.run(() => {
      this.navigationItems.forEach((element: any, index: number) => {
        var element: any = document.getElementById(`${element.name}${index}`);
        element.style.borderColor = 'transparent';
      });
      var element2: any = document.getElementById(`${page.name}${index}`);
      element2.style.borderColor = '#0087A5';

      this.router.navigate([this.helperService.changeRouterLink(page.name)]);
      this.returnUrl = this.helperService.changeRouterLink(page.name);

      if (page.screenId != -1) {
        this.helperService.updateMainComponent(page.screenId);
      }
      this.addNormalHeaderColor();
      this.firestoreService.setSnapshotToNull();
      this.dataService.passScrollEvent(false);
    });
  }

  navigateToWatchList() {
    this.router.navigate(['watch-list']);
    var element: any = document.getElementById(`watchList`);
    element.style.borderColor = 'transparent';
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/home']);
    this.dataService.refreshLoginCredential(true);
  }

  showSearchBar() {
    this.isShowSearchBar = true;
    setTimeout(() => {
      var element: any = document.getElementById('searchInput');
      this.route.queryParams.subscribe(x => {
        if (x.search != undefined) {
          element.value = x.search;
        } else {
          element.value = '';
        }
      });
      element.focus();
    }, 0);
  }

  hideSearchBar() {
    this.searchKey = '';
    if (!!this.searchKey && this.searchKey.length > 0) {
      this.isShowSearchBar = true;
    } else {
      this.isShowSearchBar = false;
    }
  }

  search(event) {
    // if(event) {
    //   console.log(event.target.value)
    // } else {
    //   console.log('in out')
    // }
    if (!!this.searchKey && this.searchKey.length > 0) {
      this.router.navigate(['other', 'search', 'elastic'], {queryParams: {search: this.searchKey}});
      this.addScollHeaderColor();
    } else {
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['/home']);
      }
      // this.returnUrl = undefined;
      this.addNormalHeaderColor();
    }
  }

  openLoginDialog() {
    this.router.navigate(['/account/login']);
  }

  showLoginContainer() {
    let loginContainerElement = document.getElementById('loginContainer');
    if (!!loginContainerElement) {
      loginContainerElement.style.display = 'block';
      this.arrowClicked = true;
    }
  }

  hideLoginContainer() {
    setTimeout(() => {
      let loginContainerElement = document.getElementById('loginContainer');
      if (!!loginContainerElement) {
        loginContainerElement.style.display = 'none';
        this.arrowClicked = false;
      }
    }, 1000);
  }

  openProfileUI(profiles: any) {
    this.matDialog.open(ProfileComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        profiles: profiles
      }
    }).afterClosed().subscribe((res) => {
    });
  }

  navigateToSettings() {
    this.helperService.refreshSource.next(null);
    this.router.navigate(['settings/account']);
  }

  arrowOpen() {
    setTimeout(() => {
      this.arrowClicked = true;
    }, 1000);
  }

  arrowClose() {
    this.arrowClicked = false;
  }
  getShortName(name: string) {
    if (name.length < 8) {
      return name;
    } else {
      let shortName: string = name.substring(0, 8) + '...';
      return shortName;
    }
  }


  openNotifications() {
    this.notifyClicked = !this.notifyClicked;
    if (this.notifyClicked) {
      if (this.vf) {
        this.vf.remove();
      }
      let resolver = this.componentFactoryResolver.resolveComponentFactory(NotificationsComponent);
      let componentFactory = this.vf.createComponent(resolver);

      const loaderComponentElement = componentFactory.location.nativeElement;
      const sibling: HTMLElement = loaderComponentElement.previousSibling;
      sibling.firstChild.firstChild.appendChild(loaderComponentElement)
    } else {
      if(this.vf) {
        this.vf.remove()
      }
    }
  }
}
